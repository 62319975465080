// import { delay } from 'redux-saga';
import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';
import { api, withAsync, messagesActions } from 'state-template';

import { submitAsyncForm } from '../../utils/formUtils';
import * as actions from './actions';
import * as C from './constants';

export function* getApplicationList() {
  const { API_URL } = window.config;
  const url = `${API_URL}/api/Applications/ForOfficial`;
  const data = yield call(api.requestWithToken, url);
  yield put(actions.getApplicationListSuccess(data));
}

export function* getApplicantList() {
  const { API_URL } = window.config;
  const url = `${API_URL}/api/Applicant`;
  const data = yield call(api.requestWithToken, url);
  yield put(actions.getApplicantListSuccess(data));
}

// based off role gets info of all officials or for only self
export function* getOfficialList() {
  const { API_URL } = window.config;
  const url = `${API_URL}/api/Official`;
  const data = yield call(api.requestWithToken, url);
  yield put(actions.getOfficialListSuccess(data));
}

export function* updateOfficial(action) {
  const { API_URL } = window.config;
  const url = `${API_URL}/api/Official/updateJson`;
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
  };
  console.log("from update: ", action.payload)
  const data = yield call(api.requestWithToken, url, options);
  // refresh official profile and applications after updated
  yield put(actions.getOfficialListRequest());
  yield put(actions.getApplicationListRequest());
  yield put(actions.updateOfficialSuccess(data));
  // register success message into global messaging system
  yield put(messagesActions.registerMessage({
    id: 'updateOfficialSuccess',
    children: 'Successfully Updated!',
    variant: 'primary',
  }));
}

export function* newOfficial(action) {
  const { API_URL } = window.config;
  const url = `${API_URL}/api/Official/addNewOfficialJson`;
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
  };

  const config = {
    formName: 'NewOfficialForm',
    callApi: call(api.requestWithToken, url, options),
    onSuccess: actions.newOfficialSuccess,
    errorMessage: 'User Already Exists.',
    successMessage: 'An email has been sent to this official.',
  };

  yield submitAsyncForm(config);
}

export function* deleteOfficial(action) {
  const { API_URL } = window.config;
  const url = `${API_URL}/api/Official/${action.payload}`;
  const options = {
    method: 'DELETE',
  };
  yield call(api.requestWithToken, url, options);
  yield put(actions.deleteOfficialSuccess(action.payload));
  // register success message into global messaging system
  yield put(messagesActions.registerMessage({
    id: 'deleteOfficialSuccess',
    children: 'Successfully Deleted!',
    variant: 'primary',
  }));
}

export default function* dashboardSaga() {
  yield all([
    takeEvery(C.GET_APPLICATION_LIST_REQUEST, withAsync(getApplicationList)),
    takeEvery(C.GET_APPLICANT_LIST_REQUEST, withAsync(getApplicantList)),
    takeEvery(C.GET_OFFICIAL_LIST_REQUEST, withAsync(getOfficialList)),
    takeEvery(C.UPDATE_OFFICIAL_REQUEST, withAsync(updateOfficial)),
    takeEvery(C.NEW_OFFICIAL_REQUEST, newOfficial),
    takeEvery(C.DELETE_OFFICIAL_REQUEST, withAsync(deleteOfficial)),
  ]);
}
