import { createAction } from 'redux-actions';

import * as C from './constants';

// applications
export const getApplicationListRequest = createAction(C.GET_APPLICATION_LIST_REQUEST);
export const getApplicationListSuccess = createAction(C.GET_APPLICATION_LIST_SUCCESS);

// applicant
export const getApplicantListRequest = createAction(C.GET_APPLICANT_LIST_REQUEST);
export const getApplicantListSuccess = createAction(C.GET_APPLICANT_LIST_SUCCESS);

// officials
export const getOfficialListRequest = createAction(C.GET_OFFICIAL_LIST_REQUEST);
export const getOfficialListSuccess = createAction(C.GET_OFFICIAL_LIST_SUCCESS);

export const updateOfficialRequest = createAction(C.UPDATE_OFFICIAL_REQUEST);
export const updateOfficialSuccess = createAction(C.UPDATE_OFFICIAL_SUCCESS);

export const newOfficialRequest = createAction(C.NEW_OFFICIAL_REQUEST);
export const newOfficialSuccess = createAction(C.NEW_OFFICIAL_SUCCESS);

export const deleteOfficialRequest = createAction(C.DELETE_OFFICIAL_REQUEST);
export const deleteOfficialSuccess = createAction(C.DELETE_OFFICIAL_SUCCESS);
