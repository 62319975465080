import { handleActions } from "redux-actions";

import * as C from "./constants";

export const initialState = {
  assistanceList: [],
};

// update assistance list, formatted as options for a select
export const getAssistanceListSuccess = (state, action) => {
  const assistanceList = action.payload.map((x) => ({
    value: x.programNumber,
    label: `${x.programNumber} ${x.programTitle}`,
  }));

  return { ...state, assistanceList };
};

export default handleActions(
  {
    [C.GET_ASSISTANCE_LIST_SUCCESS]: getAssistanceListSuccess,
  },
  initialState
);
