import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const getIsLoggedIn = () =>
  createSelector(selectAuth, (x) => x.isLoggedIn);
export const getIsResetting = () =>
  createSelector(selectAuth, (x) => x.isResetting);
export const getRole = () => createSelector(selectAuth, (x) => x.role);
export const getError = () =>
  createSelector(selectAuth, (x) => {
    return x.auth_error;
  });
export const getIsLockedOut = () =>
  createSelector(selectAuth, (x) => 
  x.isLockedOut);
