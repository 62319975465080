import { createSelector } from 'reselect';
import schema from './components/FiltersPanel/schema';

const selectSubmissions = (state) => state.submissions;
const selectNames = Object.values(schema).filter((x) => x.options).map((x) => x.name);

export const getSubmissionList = () => createSelector(selectSubmissions, (x) => {
  const { filters, submissionList, search } = x;
  const { searchString } = search;
  const allFilters = Object.keys(filters);

  if (!allFilters.length && !searchString) return submissionList;

  const dateRangeFilterNames = [];
  const textFilterNames = [];
  const selectFilterNames = [];

  allFilters.forEach((filter) => {
    if (filter.match(/--start|--end/)) {
      dateRangeFilterNames.push(filter);      
    } else if (selectNames.includes(filter)) {
      selectFilterNames.push(filter);
    } else {
      textFilterNames.push(filter);
    }
  });

  return submissionList.filter((result) => {
    let found = true;

    // Search on searchString
    if (searchString) {
      found = searchResult(searchString, result);
    }

    // filter by text fields - partial matches
    const matchesTextFields = textFilterNames.every((name) => {
      if (typeof result[name] === 'string') {
        return result[name].toLowerCase().match(filters[name].toLowerCase());
      }
      return +result[name] === +filters[name];
    });
    
    // filter by selects - exact matches
    const matchesSelectFields = selectFilterNames.every((name) => {
      if (!result[name]) return false;
      return result[name].toLowerCase() === filters[name].toLowerCase();
    });

    // filter by date range - within start and end dates
    let datesRangesValid = true;
    const foundDates = {};
    
    dateRangeFilterNames.forEach((withPostfix) => {
      const name = withPostfix.replace(/--start|--end/, '');
      
      // check if already processed (ex. did --end, dont do --start when found)
      if (!foundDates[name]) {
        let startDate = filters[dateRangeFilterNames.find((x) => x === `${name}--start`)];      
        let endDate = filters[dateRangeFilterNames.find((x) => x === `${name}--end`)];
        
        
        startDate = startDate.split('-');
        startDate = new Date(startDate[0], startDate[1] - 1, startDate[2]); //months start at 0 so we need to subtract 1
        
        endDate = endDate.split('-');
        endDate = new Date(endDate[0], endDate[1] - 1, endDate[2]);
        
        var date = new Date(result[name]);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const isBeforeStart = startDate && date < startDate;
        const isAfterEnd = endDate && date > endDate;            
        
        if (isBeforeStart || isAfterEnd) {
          datesRangesValid = false;
        }
        foundDates[name] = 1;      
      }
    });
    return found && matchesTextFields && matchesSelectFields && datesRangesValid;
  });
});

export const getFilters = () => createSelector(selectSubmissions, (x) => x.filters);

const searchResult = (searchString, value) => {
  const keys = Object.keys(value);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (value[key]) {
      if (typeof value[key] === 'string') {
        if (value[key].toLowerCase().match(searchString.toLowerCase())) {
          return true;
        }
      } else if (value[key].toString().indexOf(searchString) > -1) {
        return true;
      }
    }
  }
  return false;
}
