import React from 'react';
import { A } from 'state-template';

import { formattedDate } from '../../../utils/date';
const {API_URL}= window.config;

export const columns = [
  {
    sort: true,
    dataField: 'cfda',
    text: 'CFDA Number',
  }, {
    sort: true,
    dataField: 'orgName',
    text: 'Applicant Name',
  }, {
    sort: true,
    dataField: 'fileName',
    text: 'SF-424 Form',
    formatter: (e, row) => (
      <A
        href={`${API_URL}/api/Applications/Download/${row.id}`}
        text={row.fileName}
      />
    ),
  }, {
    sort: true,
    dataField: 'county',
    text: 'County',
  }, {
    sort: true,
    dataField: 'assembly',
    text: 'Assembly District',
  }, {
    sort: true,
    dataField: 'senate',
    text: 'Senate District',
  }, {
    sort: true,
    dataField: 'submission',
    text: 'Date Received',
    formatter: formattedDate,
  },
];
