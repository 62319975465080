import { all, takeEvery, call, put } from "redux-saga/effects";
import { api, withAsync } from "state-template";

import { buildFormData } from "../../utils/api";
import { submitAsyncForm } from "../../utils/formUtils";
import * as actions from "./actions";
import * as C from "./constants";

export function* getAssistanceList() {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/AssistanceListing`;
  const data = yield call(api.request, url);
  yield put(actions.getAssistanceListSuccess(data));
}

export function* submitForm(action) {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Applications`;
  const { history, values } = action.payload;
  const options = {
    method: "POST",
    body: buildFormData(values),
    headers: {},
  };

  const config = {
    formName: "AssistanceForm",
    callApi: call(api.request, url, options),
    onSuccess: actions.submitFormSuccess,
    successMessage: "Successfully Submitted!",
  };

  try {
    yield submitAsyncForm(config);
    history.push("/submissions");
  } catch {
    //stuff
  }
}

export default function* homeSaga() {
  yield all([
    takeEvery(C.SUBMIT_FORM_REQUEST, submitForm),
    takeEvery(C.GET_ASSISTANCE_LIST_REQUEST, withAsync(getAssistanceList)),
  ]);
}
