import React from 'react';
import T from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Page } from 'state-template';

import RequestResetForm from './RequestResetForm';
import LoginForm from './LoginForm';
import * as selectors from './selectors';

export class Auth extends React.PureComponent {
  componentDidMount() {
    this.redirectLoggedIn(this.props);
  }

  componentDidUpdate() {
    this.redirectLoggedIn(this.props);
  }

  redirectLoggedIn = ({ history, isLoggedIn }) => {
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  };

  render() {
    const { isResetting, isLockedOut } = this.props;
    const title = isResetting ? 'Reset Password' : 'Login';

    // Redirect if logged in or show a lockout message if locked out
    if(isLockedOut) {
      return (
        <Page title = {title}>
          <div className = {'row justify-content-center'}>
            <div className={'col-lg-6 col-md-8'}>
              <div>Your account has been locked due to multiple
            failed login attempts. Please try again in 2 hours.
              </div> 
            </div>
          </div>
        </Page>
      );
    }

    return (
      <Page title={title}>
        <div className={'row justify-content-center'}>
          <div className={'col-lg-6 col-md-8'}>
            {isResetting ? <RequestResetForm /> : <LoginForm />}
          </div>
        </div>
      </Page>
    );
  }
}

Auth.propTypes = {
  history: T.shape({
    push: T.func.isRequired,
  }).isRequired,
  isLoggedIn: T.bool.isRequired, // eslint-disable-line
  isResetting: T.bool.isRequired,
  isLockedOut: T.bool.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectors.getIsLoggedIn(),
  isResetting: selectors.getIsResetting(),
  isLockedOut: selectors.getIsLockedOut(),
});

const withRedux = connect(mapStateToProps)(Auth);
const withRouting = withRouter(withRedux);

export default withRouting;
