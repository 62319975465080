export const columns = [
  {
    sort: true,
    dataField: 'email',
    text: 'Email Address',
  }, {
    sort: true,
    dataField: 'orgName',
    text: 'Organization',
  },
];
