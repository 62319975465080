import React from 'react';

import { formattedDate } from '../../utils/date';
const { API_URL } = window.config;

export const columns = [
  {
    sort: true,
    dataField: 'orgName',
    text: 'Applicant',
    minWidth: 150,
    maxWidth: 250,
  },
  // {
  //   sort: true,
  //   dataField: 'email',
  //   text: 'Email',
  //   minWidth: 150,
  //   maxWidth: 200,
  //   formatter: (e, row) => (
  //     <a href={`mailto:${row.email}`}>{row.email}</a>
  //   ),
  // },
  {
    sort: true,
    dataField: 'fileName',
    text: 'SF-424 Form',
    minWidth: 150,
    maxWidth: 200,
    formatter: (e, row) => (
      <a href={`${API_URL}/api/Applications/Download/${row.id}`}>{row.fileName} <span style={{ color: "red" }} className={'ca-gov-icon-pdf'}></span></a>
    ),
  }, {
    sort: true,
    dataField: 'fedAgency',
    text: 'Federal Agency',
    maxWidth: 100,
  }, {
    sort: true,
    dataField: 'cfda',
    text: 'CFDA Number',
    maxWidth: 100,
  }, {
    sort: true,
    dataField: 'city',
    text: 'City/Town',
    maxWidth: 120,
  }, {
    sort: true,
    dataField: 'county',
    text: 'County',
    maxWidth: 120,
  }, {
    sort: true,
    dataField: 'assembly',
    text: 'Assembly District',
    maxWidth: 100,
  }, {
    sort: true,
    dataField: 'senate',
    text: 'Senate District',
    maxWidth: 100,
  }, {
    sort: true,
    dataField: 'projStart',
    text: 'Project Start Date',
    formatter: formattedDate,
    minWidth: 90,
    maxWidth: 90,
  }, {
    sort: true,
    dataField: 'projEnd',
    text: 'Project End Date',
    formatter: formattedDate,
    minWidth: 90,
    maxWidth: 90,
  }, {
    sort: true,
    dataField: 'submission',
    text: 'Date Received',
    formatter: formattedDate,
    minWidth: 90,
    maxWidth: 90,
  },
];

export const defaultSorted = [{
  dataField: 'submission',
  order: 'desc',
}];
