import React from 'react';
import * as T from 'prop-types';

const Panel = ({
  className, title, children, ...rest
}) => (
  <div className={`Panel ${className}`} {...rest}>
    {title && <h3>{title}</h3>}
    {children}
  </div>
);

Panel.propTypes = {
  title: T.string,
  className: T.string,
  children: T.node.isRequired,
};

Panel.defaultProps = {
  title: '',
  className: '',
};

export default Panel;
