import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, FieldInput } from 'state-template';

import * as actions from '../../actions';

export class SearchBox extends React.PureComponent {
  onSubmit = (values) => {
    const { updateSearch } = this.props;
    updateSearch(values);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className={'SearchBox'}>
        <FieldInput
          name={'searchString'}
          aria-label={'search'}
        />

        <Button
          iconProps={{ name: 'search-right', srOnly: 'Submit Search' }}
          className={'SearchBox__icon'}
          type={'submit'}
        />
      </form>
    );
  }
}

SearchBox.propTypes = {
  handleSubmit: T.func.isRequired,
  updateSearch: T.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  updateSearch: (props) => dispatch(actions.updateSearch(props)),
});

const withReduxForm = reduxForm({ form: 'search', destroyOnUnmount: false })(SearchBox);
const withRedux = connect(null, mapDispatchToProps)(withReduxForm);

export default withRedux;
