import React from 'react';
import { Page, Panel } from 'state-template';

import AssistanceForm from './AssistanceForm';
import messages from './messages';

export class Home extends React.PureComponent {
  componentDidMount() {}

  render() {
    return (
      <Page title={'Home'}>
        {messages.intro}
        <Panel title={messages.reviewHeader} variant={'overstated'}>
          {messages.reviewText}
          <AssistanceForm />
        </Panel>
      </Page>
    );
  }
}

export default Home;
