export const VERIFY_TOKEN_REQUEST = 'Registration/VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'Registration/VERIFY_TOKEN_SUCCESS';

export const REGISTER_OFFICIAL_REQUEST = 'Registration/REGISTER_OFFICIAL_REQUEST';
export const REGISTER_OFFICIAL_SUCCESS = 'Registration/REGISTER_OFFICIAL_SUCCESS';
export const REGISTER_OFFICIAL_FAILURE = 'Registration/REGISTER_OFFICIAL_FAILURE';

export const RESET_PASSWORD_REQUEST = 'Registration/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'Registration/RESET_PASSWORD_SUCCESS';

export const CONFIRM_REQUEST = 'Registration/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'Registration/CONFIRM_SUCCESS';

export const RESET_PASSWORD_DUPLICATE = 'Registration/RESET_PASSWORD_DUPLICATE';

