// applications
export const GET_APPLICATION_LIST_REQUEST = 'Dashboard/GET_APPLICATION_LIST_REQUEST';
export const GET_APPLICATION_LIST_SUCCESS = 'Dashboard/GET_APPLICATION_LIST_SUCCESS';

// applicant
export const GET_APPLICANT_LIST_REQUEST = 'Dashboard/GET_APPLICANT_LIST_REQUEST';
export const GET_APPLICANT_LIST_SUCCESS = 'Dashboard/GET_APPLICANT_LIST_SUCCESS';

// officials
export const GET_OFFICIAL_LIST_REQUEST = 'Dashboard/GET_OFFICIAL_LIST_REQUEST';
export const GET_OFFICIAL_LIST_SUCCESS = 'Dashboard/GET_OFFICIAL_LIST_SUCCESS';

export const UPDATE_OFFICIAL_REQUEST = 'Dashboard/UPDATE_OFFICIAL_REQUEST';
export const UPDATE_OFFICIAL_SUCCESS = 'Dashboard/UPDATE_OFFICIAL_SUCCESS';

export const NEW_OFFICIAL_REQUEST = 'Dashboard/NEW_OFFICIAL_REQUEST';
export const NEW_OFFICIAL_SUCCESS = 'Dashboard/NEW_OFFICIAL_SUCCESS';

export const DELETE_OFFICIAL_REQUEST = 'Dashboard/DELETE_OFFICIAL_REQUEST';
export const DELETE_OFFICIAL_SUCCESS = 'Dashboard/DELETE_OFFICIAL_SUCCESS';
