import { handleActions } from 'redux-actions';

import * as C from './constants';

export const initialState = {
  isLoggedIn: false,
  isResetting: false,
  auth_error: null,
  loginAttempts: 0,
  isLockedOut: false,
};

export const incrementLoginAttempts = (state, action) => ({
  ...state,
  loginAttempts: state.loginAttempts + 1,
});

export const resetLoginAttempts = (state, action) => ({
  ...state,
  ...action.payload,
  loginAttempts: 0,
  isLockedOut: false,
});

export const enforceLockout = (state, action) => ({
  ...state,
  ...action.payload,
  isLockedOut: true,
});

export const stillLockedOut = (state, action) => ({
  ...state,
  isLockedOut: true,
});

export const loginSuccess = (state, action) => ({
  ...state,
  ...action.payload,
  isLoggedIn: true,
});

export const loginFailed = (state, action) => {
  return {
    ...state,
    ...action.payload,
    isLoggedIn: false,
    loginAttempts: state.loginAttempts + 1,
    isLockedOut: state.loginAttempts + 1 >= 5,
  };
};

export const logout = () => initialState;

export const setIsResetting = (state, action) => ({
  ...state,
  isResetting: action.payload,
});

export default handleActions(
  {
    [C.LOGIN_SUCCESS]: loginSuccess,
    [C.LOGIN_FAILURE]: loginFailed,
    [C.LOGOUT_DONE]: logout,
    [C.SET_IS_RESETTING]: setIsResetting,
    [C.INCREMENT_LOGIN_ATTEMPTS]: incrementLoginAttempts,
    [C.RESET_LOGIN_ATTEMPTS]: resetLoginAttempts,
    [C.ENFORCE_LOCKOUT]: enforceLockout,
    [C.STILL_LOCKED_OUT]: stillLockedOut,
  },
  initialState
);
