import { handleActions } from 'redux-actions';

import * as C from './constants';
import * as C_AUTH from '../Auth/constants';

export const initialState = {
  applicationList: [],
  applicantList: [],
  officialList: [],
};

export const getApplicationListSuccess = (state, action) => ({
  ...state,
  applicationList: action.payload,
});

export const getApplicantListSuccess = (state, action) => ({
  ...state,
  applicantList: action.payload,
});

export const getOfficialListSuccess = (state, action) => ({
  ...state,
  officialList: action.payload,
});

export const deleteOfficialSuccess = (state, action) => ({
  ...state,
  officialList: state.officialList.filter(x => x.id !== action.payload),
});

export const logout = () => initialState;

export default handleActions({
  [C.GET_APPLICATION_LIST_SUCCESS]: getApplicationListSuccess,
  [C.GET_APPLICANT_LIST_SUCCESS]: getApplicantListSuccess,
  [C.GET_OFFICIAL_LIST_SUCCESS]: getOfficialListSuccess,
  [C.DELETE_OFFICIAL_SUCCESS]: deleteOfficialSuccess,
  [C_AUTH.LOGOUT_DONE]: logout,
}, initialState);
