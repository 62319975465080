export const LOGIN_REQUEST = 'Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'Auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'Auth/LOGIN_FAILURE';

export const LOGOUT = 'Auth/LOGOUT';
export const LOGOUT_DONE = 'Auth/LOGOUT_DONE';

export const REQUEST_RESET_REQUEST = 'Auth/REQUEST_RESET_REQUEST';
export const REQUEST_RESET_SUCCESS = 'Auth/REQUEST_RESET_SUCCESS';
export const REQUEST_RESET_FAILURE = 'Auth/REQUEST_RESET_FAILURE';
export const REQUEST_RESET_DUPLICATE = 'Auth/REQUEST_RESET_DUPLICATE';

export const INCREMENT_LOGIN_ATTEMPTS = 'Auth/INCREMENT_LOGIN_ATTEMPTS';
export const RESET_LOGIN_ATTEMPTS = 'Auth/RESET_LOGIN_ATTEMPTS';
export const ENFORCE_LOCKOUT ='Auth/ENFORCE_LOCKOUT';
export const STILL_LOCKED_OUT = 'Auth/STILL_LOCKED_OUT';
export const SET_IS_RESETTING = 'Auth/SET_IS_RESETTING';
