import { handleActions } from 'redux-actions';

import * as C from './constants';

export const initialState = {
  submissionList: [],
  filters: {},
  search: {},
};

export const updateSearch = (state, action) => ({
  ...state,
  search: action.payload,
});

export const getSubmissionsSuccess = (state, action) => ({
  ...state,
  submissionList: action.payload,
});

export const updateFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

export default handleActions({
  [C.UPDATE_SEARCH]: updateSearch,
  [C.GET_SUBMISSION_LIST_SUCCESS]: getSubmissionsSuccess,
  [C.UPDATE_FILTERS]: updateFilters,
}, initialState);
