import { createAction } from 'redux-actions';

import * as C from './constants';

export const verifyTokenRequest = createAction(C.VERIFY_TOKEN_REQUEST);
export const verifyTokenSuccess = createAction(C.VERIFY_TOKEN_SUCCESS);

export const registerOfficialRequest = createAction(C.REGISTER_OFFICIAL_REQUEST);
export const registerOfficialSuccess = createAction(C.REGISTER_OFFICIAL_SUCCESS);
export const registerOfficialFailure = createAction(C.REGISTER_OFFICIAL_FAILURE);

export const resetPasswordRequest = createAction(C.RESET_PASSWORD_REQUEST);
export const resetPasswordSuccess = createAction(C.RESET_PASSWORD_SUCCESS);

export const confirmRequest = createAction(C.CONFIRM_REQUEST);
export const confirmSuccess = createAction(C.CONFIRM_SUCCESS);

export const resetPasswordDuplicateFailure = createAction(C.RESET_PASSWORD_DUPLICATE);

