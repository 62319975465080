import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, formValueSelector } from 'redux-form';

import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import {
  Button,
  ConnectedAsync,
  FieldInput,
  FieldRadioButtons,
  fieldOptions,
  validate,
} from 'state-template';

import * as actions from '../actions';

import * as caOptions from '../../../utils/caOptions';

// TODO: move to ST
const validatePassMatch = (value, state) => (
  state.pass === value
    ? undefined
    : 'Passwords do not match'
);

const validatePassStrength = (value, state) => {
  var validationMessage = '';
  if (state.pass?.length < 15) {
    return 'Password is too short, please use a minimum of 15 characters.';
  }
  else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(state.pass)) {
    return 'Please use a strong password containing at least one uppercase letter, one lowercase letter, one number, and one special character.';
  }
  else if (state.pass?.length > 128){
    return 'Password is too long.';
  }   
}

const getNumberOptions = (count, prefix) => Array(count)
  .fill(0)
  .map((x, i) => ({
    value: prefix + String(i + 1),
    label: String(i + 1),
  }));

const getFieldOptions = (arr, prefix) => {
  if (arr) {
    var tmp = arr.map((x, i) => ({
      value: prefix + x.value,
      label: x.label
    }));
    return tmp;
  }

  return [];
}

export const districtOptions = {
  assembly: [{
    label: 'Assembly',
    options: getNumberOptions(80, 'a_'),
  }],
  senate: [{
    label: 'Senate',
    options: getNumberOptions(40, 's_'),
  }],
  city: [{
    label: 'City',
    options: getFieldOptions(caOptions.cityOptionsCA, "city_"),
  }],
  county: [{
    label: 'County',
    options: getFieldOptions(fieldOptions.countyOptionsCA, "county_")
  }],
};

export const listBoxIcons = {
  moveLeft: <span className="ca-gov-icon-caret-left" />,
  moveAllLeft: <span key={0} className="ca-gov-icon-caret-two-left" />,
  moveRight: <span className="ca-gov-icon-caret-right" />,
  moveAllRight: <span key={0} className="ca-gov-icon-caret-two-right" />,
};

export const officeOptions = [
  { value: 'assembly', label: 'Assembly' },
  { value: 'senate', label: 'Senate' },
  { value: 'city', label: 'City' },
  { value: 'county', label: 'County' },
];

export class RegistrationForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      availableDistrictOptions: districtOptions["senate"][0].options
    };
  }

  componentDidUpdate(prevProps) {
    const { officeValue } = this.props;

    if (officeValue !== prevProps.officeValue) {
      this.updateDistrictOptions(officeValue);
    }
  }

  getAllDistrictOptions = () => {
    var tmp = [];
    for (var propName in districtOptions) {
      tmp = tmp.concat(districtOptions[propName]);
    }
    return tmp;
  }

  updateDistrictOptions = (officeValue) => {
    // have a office selected, update list
    if (officeValue) {
      var tmp = [];
      for (var opt in districtOptions[officeValue][0].options) {
        tmp.push(districtOptions[officeValue][0].options[opt].value);
      }
      this.setState({ availableDistrictOptions: tmp });
    }
    console.log(officeValue)
  }

  onSubmit = (values) => {
    const { registerOfficial } = this.props;
    const { selected } = this.state;
    const { pass } = this.state;

    var districts = [];
    var cities = [];
    var counties = [];

    selected.forEach(function (value) {
      var pre = value.substring(0, value.indexOf("_"));
      var val = value.substring(value.indexOf("_") + 1);

      switch (pre) {
        case "city":
          cities.push(val);
          break;
        case "county":
          counties.push(val);
          break;
        case "a":
        case "s":
        default:
          districts.push(value);
          break;
      }
    });

    values.district = districts;
    values.county = counties;
    values.city = cities;

    registerOfficial(values);
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { availableDistrictOptions } = this.state;

    return (
      <ConnectedAsync>
        <div className={'group'}>
          <div className={'container'}>
            <strong><p>Register here to receive notifications of submissions in your area. Applicants submitting notifications do NOT need to register.</p></strong>
          </div>
        </div>
        <form onSubmit={handleSubmit(this.onSubmit)} className={'m-t-md'}>
          <div className={'group'}>
            <FieldInput name={'fName'} label={'First Name'} className={'third'} required />
            <FieldInput name={'mName'} label={'Middle Name'} className={'third'} />
            <FieldInput name={'lName'} label={'Last Name'} className={'third'} required />
          </div>
          <div className={'group'}>
            <FieldInput
              required
              name={'email'}
              label={'Email'}
              className={'full-width'}
              validate={[validate.isValidEmail]}
            />
          </div>
          <div className={'group'}>
            <div className={'container'}>
              <strong>STATE AND LOCAL ELECTED OFFICIALS:</strong>
              <p>Select an area to request email notifications of submitted applications for federal assistance in your region.</p>
              <p style={{textAlign: 'right'}}><strong>Your Selections:</strong></p>
            </div>
            <FieldRadioButtons
              name={'office'}
              className={'third'}
              options={officeOptions}
            />
            <div className={'two-thirds'}>
              <DualListBox
                required
                id={'district'}
                name={'district'}
                options={this.getAllDistrictOptions()}
                selected={this.state.selected}
                available={availableDistrictOptions}
                onChange={(selected) => {
                  selected = selected.filter(Boolean).length === 0 ? undefined : selected.filter(Boolean);
                  this.setState({ selected });
                }}
                icons={listBoxIcons}
              />
            </div>
          </div>
          <div className={'group'}>
            <FieldInput 
              name={'pass'} 
              label={'Password'} 
              type={'password'} 
              className={'half'} 
              validate={[validatePassStrength]}
              required/>
            <FieldInput name={'passConfirm'} label={'Confirm Password'} type={'password'} className={'half'} validate={[validatePassMatch]} required />
          </div>

          <div className={'p-y-sm'}>
            <Button
              type={'submit'}
              text={'Submit'}
              variant={'primary'}
              className={'pull-right'}
              disabled={pristine}
            />
          </div>
        </form>
      </ConnectedAsync>
    );
  }
}

RegistrationForm.propTypes = {
  token: T.string,
  handleSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  officeValue: T.string,
  registerOfficial: T.func.isRequired,
};

RegistrationForm.defaultProps = {
  officeValue: null,
};

export const mapStateToProps = createStructuredSelector({
  officeValue: state => formValueSelector('RegistrationForm')(state, 'office'),
});

export const mapDispatchToProps = dispatch => ({
  registerOfficial: values => dispatch(actions.registerOfficialRequest(values)),
});

const withReduxForm = reduxForm({
  form: 'RegistrationForm',
  destroyOnUnmount: false
})(RegistrationForm);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

export default withRedux;
