import { handleActions } from 'redux-actions';

import * as C from './constants';
import * as C_AUTH from '../Auth/constants';

export const initialState = {
  token: {},
  isRegistered: false,
  isErr: false,
  isConfirmed: false
};

export const verifyTokenSuccess = (state, action) => ({
  ...state,
  token: action.payload,
});

export const registerOfficialSuccess = state => ({
  ...state,
  isRegistered: true,
});

export const registerOfficialFailure = state => ({
  ...state,
  isErr: true,
});


export const confirmSuccess = state => ({
  ...state,
  isConfirmed: true,
});

export const resetPasswordDuplicateFailure = state => ({
  ...state,
  isErr: true,
});

export const logout = () => initialState;

export default handleActions({
  [C.VERIFY_TOKEN_SUCCESS]: verifyTokenSuccess,
  [C.REGISTER_OFFICIAL_SUCCESS]: registerOfficialSuccess,
  [C.REGISTER_OFFICIAL_FAILURE]: registerOfficialFailure,
  [C.CONFIRM_SUCCESS]: confirmSuccess,
  [C.RESET_PASSWORD_DUPLICATE]: resetPasswordDuplicateFailure,
  [C_AUTH.LOGOUT_DONE]: logout,
}, initialState);
