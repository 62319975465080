import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import StateTemplate, { auth } from 'state-template';

import { routesByKey } from './routes';
import oprLogo from '../../images/oprLogo.png';

import * as authActions from '../Auth/actions';
import * as authSelectors from '../Auth/selectors';

const brandingLogo = {
  src: oprLogo,
  alt: 'Office of Planning and Research Logo',
  to: '/',
};

const contactLink = {
  text: 'Contact Us',
  href: 'https://www.opr.ca.gov/about/contact-us.html',
};

export class App extends React.PureComponent {
  componentDidMount() {
    this.loginFromStorage();
  }

  // log user in if valid token found in their local storage
  loginFromStorage = () => {
    const { loginRequest } = this.props;
    const jwt = auth.getToken();
    const isValid = auth.validToken(jwt);
    // update redux with login details if valid token
    if (isValid) {
      loginRequest();
    }
  }

  buildAuthRoute = (routes) => {
    const { isLoggedIn, logout } = this.props;

    let name = 'Login';
    let onClick = null;
    if (isLoggedIn) {
      name = 'Logout';
      onClick = logout;
    }

    return ({
      ...routes,
      auth: {
        ...routes.auth,
        name,
        onClick,
      },
    });
  }

  filterLoginRoutes = (routes) => {
    const { isLoggedIn } = this.props;    
    if (!isLoggedIn) return routes;
    return routes.filter((x) => {
      if (!x.visibility) { return true; }
      return x.visibility !== "logout"      
    });
  }
  
  filterUnauthorizedRoutes = (routes) => {
    const { role } = this.props;
    return routes.filter((x) => {
      if (!x.roles) { return true; }
      return x.roles.includes(role);
    });
  }

  getRoutes = () => {
    const routes = routesByKey;
    const withAuthRoute = this.buildAuthRoute(routes);
    const routeValues = Object.values(withAuthRoute);
    const withAuth = this.filterUnauthorizedRoutes(routeValues);
    const loginFilter = this.filterLoginRoutes(withAuth);
    return loginFilter;
  }

  render() {
    const routes = this.getRoutes();
    return (
      <StateTemplate
        routes={routes}
        brandingLogo={brandingLogo}
        contactLink={contactLink}
        alignHeader={'right'}
        router={BrowserRouter}
      />
    );
  }
}

App.propTypes = {
  isLoggedIn: T.bool.isRequired,
  role: T.string,
  loginRequest: T.func.isRequired,
  logout: T.func.isRequired,
};

App.defaultProps = {
  role: null,
};

export const mapStateToProps = createStructuredSelector({
  isLoggedIn: authSelectors.getIsLoggedIn(),
  role: authSelectors.getRole(),
});

export const mapDispatchToProps = (dispatch) => ({
  loginRequest: () => dispatch(authActions.loginRequest()),
  logout: () => dispatch(authActions.logout()),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRedux;
