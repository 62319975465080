// TODO: ST

/**
 * Formats data into a FormData object, mapping arrays to correct format
 * @param {object} values   - values from form to format
 */
export const buildFormData = (values) => {
  const data = new FormData();
  Object.keys(values).forEach((x) => {
    const value = values[x];
    if (Array.isArray(value)) {
      // step through all values for arrays
      value.forEach((i) => {
        data.append(x, i);
      });
    } else if (value && value.name) {
      // is a file
      data.append(x, value, value.name);
    } else {
      data.append(x, value);
    }
  });
  return data;
};



