import React from "react";

// import { A } from 'state-template';
import A from "state-template/dist/components/A";

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
export default {
  intro: (
    <p>
      The State Clearinghouse (SCH) at the Governor's Office of Planning and Research (OPR) serves as the state's Single Point of
      Contact for the submission of Federal assistance notices pursuant to{" "}
      <A
        href="https://www.archives.gov/federal-register/codification/executive-order/12372.html"
        text="Presidential Executive Order No. 12372"
      />{" "}
      and{" State Administrative Manual (SAM) Sections "}
      <A
        href="https://www.dgs.ca.gov/Resources/SAM/TOC/900/0911"
        text="0911"
      />{" "}
      and{" "}      
      <A
        href="https://www.dgs.ca.gov/Resources/SAM/TOC/900/0912"
        text="0912"
      />
      . With the signing of{" "}
      <A
        href="https://leginfo.legislature.ca.gov/faces/billNavClient.xhtml?bill_id=201520160AB1348"
        text="Assembly Bill 1348"
      />{" "}
      the State Clearinghouse is responsible for reporting on all federal grants
      applied for and received in California regardless if they fall under
      Executive Order 12372. We have developed this on-line database to
      assist with that reporting.
      <br />
      <br /> Please provide your notice here before or at the same
      time as you apply for the federal grant. These notices must be provided
      to the SCH, which in turn
      publishes this information for review by State and local elected
      officials. The submittal of the SF-424 form on this website begins
      a 30-day review period.  If no comments are received, the SCH will
      NOT notify the applicant in writing.
      <br />
      <br />
      The SCH does not administer grant programs or offer any grant funding.
      Please visit{" "}
      <A href="https://www.grants.gov/" text="Grants.gov" /> for funding
      opportunities or sign up for the
      {' '}
      <A href={'https://lp.constantcontactpages.com/su/4wi9l7p'}
                className={'link-external'} text="OPR Federal Grants Update" />.
        <br />
            <br />

            State, federal, and local agencies must comply with all applicable
            statutory and regulatory requirements related to the Americans with
            Disabilities Act for documents posted and made available on internet
            websites {" "}
            (<A
                href="https://www.opr.ca.gov/accessibility.html"
                text="OPR Accessibility"
            />).

    </p>
  ),

  determinationHeader: (
    <h2 className={"m-t-md"}>
      How to Determine if Your Federal Assistance Application is Subject to
      Review
    </h2>
  ),
  determinationText: (
    <div>
      <p>
        To determine if your federal assistance application is subject to
        review, visit{" "}
        <A
          href="https://beta.sam.gov/search?index=cfda"
          text={"Assistance Listings"}
        />{" "}
        on the System for Award Management (SAM) webpage. Conduct a search for
        the federal assistance program being applied to, then review the program
        listing for information about its application requirements under
        Executive Order No. 12372. If no information is available, contact the
        federal agency identified in the program listing.
      </p>
      <p>
        To inquire about eligibility, application procedures, and criteria used
        to approve federal assistance, applicants should contact the appropriate
        federal funding agency. The SCH is unable to provide this information.
      </p>
    </div>
  ),
  reviewHeader: "Federal Assistance Application",
  reviewText: (
    <div>
      <p>
              To apply for a federal assistance program, please upload a copy of your application Standard Form 424 to the SCH. DO NOT send your
              full application package or any other supplemental information. State, federal, and local agencies must comply with all applicable
              statutory and regulatory requirements related to the Americans with Disabilities Act for documents posted and made available on internet websites.
      </p>
      <p>
        In the appropriate box on Form 424, mark that the federal assistance
        application was made available for state review under the Executive
        Order 12372 process before submitting your federal assistance
        application to the federal funding agency.
      </p>
    </div>
  ),

  noReviewHeader: "Federal Assistance Application is not Subject to Review",
  noReviewText: (
    <div>
      <p>
        If your federal assistance program is not subject to Executive Order
        12372, then no further documentation is required by the SCH.
      </p>
      <p>
        In the appropriate box on Form 424, mark that the federal assistance
        application has not been selected for state review under the Executive
        Order 12372 process before submitting your federal assistance
        application to the federal funding agency.
      </p>
    </div>
  ),
  placeOfPerformanceText: (
    <div>
      <p>
        The address where the predominant performance of the award will be
        accomplished.
      </p>
    </div>
  ),
};
