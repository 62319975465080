import { createAction } from 'redux-actions';

import * as C from './constants';

export const loginRequest = createAction(C.LOGIN_REQUEST);
export const loginSuccess = createAction(C.LOGIN_SUCCESS);
export const loginFailed = createAction(C.LOGIN_FAILURE);

export const logout = createAction(C.LOGOUT);
export const logoutDone = createAction(C.LOGOUT_DONE);

export const requestResetRequest = createAction(C.REQUEST_RESET_REQUEST);
export const requestResetSuccess = createAction(C.REQUEST_RESET_SUCCESS);
export const requestResetDuplicate = createAction(C.REQUEST_RESET_DUPLICATE);

export const incrementLoginAttempts = createAction(C.INCREMENT_LOGIN_ATTEMPTS);
export const resetLoginAttempts = createAction(C.RESET_LOGIN_ATTEMPTS);
export const enforceLockout = createAction(C.ENFORCE_LOCKOUT);
export const stillLockedOut = createAction(C.STILL_LOCKED_OUT);

export const setIsResetting = createAction(C.SET_IS_RESETTING);
