import Auth from '../Auth';
import Dashboard from '../Dashboard';
import Registration from '../Registration';
import Submissions from '../Submissions';
// import Registrants from '../Registrants';
import Home from '../Home';

import * as C from './constants';

// order here determines order in navigation menu
export const routesByKey = {
  oprHome: {
    key: 'home',
    name: 'OPR Home',
    href: 'https://www.opr.ca.gov/',
    icon: 'ca-gov-icon-home',
  },
  home: {
    key: 'home',
    name: 'Home',
    id: 'home',
    path: '/',
    exact: true,
    icon: 'ca-gov-icon-home',
    hidden: true,
    component: Home,
  },
  submissions: {
    key: 'submissions',
    name: 'Submissions',
    path: '/submissions',
    exact: true,
    icon: 'ca-gov-icon-read-book',
    component: Submissions,
  },
  // registrants: {
  //   key: 'registrants',
  //   name: 'Registrants',
  //   path: '/registrants',
  //   exact: true,
  //   icon: 'ca-gov-icon-read-book',
  //   component: Registrants,
  // },
  registration: {
     key: 'registration',
     name: 'Registration',
     path: '/registration',
     icon: 'ca-gov-icon-pencil-edit',
     exact: true,
     component: Registration,
     visibility: "logout",
   },
  dashboard: {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    exact: false,
    icon: 'ca-gov-icon-online-services',
    component: Dashboard,
    roles: [C.ROLES.ADMIN, C.ROLES.OFFICIAL],
  },
  auth: {
    key: 'auth',
    name: 'Login',
    path: '/auth',
    exact: true,
    icon: 'ca-gov-icon-person',
    component: Auth,
  },
};

export default Object.values(routesByKey);
