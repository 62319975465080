import { handleActions } from 'redux-actions';

import * as C from './constants';

export const initialState = {
  registrantList: [],
};

export const getRegistrantsSuccess = (state, action) => ({
  ...state,
  registrantList: action.payload,
});

export default handleActions({
  [C.GET_REGISTRANT_LIST_SUCCESS]: getRegistrantsSuccess,
}, initialState);
