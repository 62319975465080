import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, formValueSelector, change } from 'redux-form';
import {
  ConnectedAsync,
  Button,
  FieldInput,
  FieldRadioButtons,
  fieldOptions,
} from 'state-template';

import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as caOptions from '../../../utils/caOptions';

const getNumberOptions = (count, prefix) =>
  Array(count)
    .fill(0)
    .map((x, i) => ({
      value: prefix + String(i + 1),
      label: String(i + 1),
    }));

const getFieldOptions = (arr, prefix) => {
  if (arr) {
    var tmp = arr.map((x, i) => ({
      value: prefix + x.value,
      label: x.label,
    }));
    return tmp;
  }

  return [];
};

export const districtOptions = {
  assembly: [
    {
      label: 'Assembly',
      options: getNumberOptions(80, 'a_'),
    },
  ],
  senate: [
    {
      label: 'Senate',
      options: getNumberOptions(40, 's_'),
    },
  ],
  city: [
    {
      label: 'City',
      options: getFieldOptions(caOptions.cityOptionsCA, 'city_'),
    },
  ],
  county: [
    {
      label: 'County',
      options: getFieldOptions(fieldOptions.countyOptionsCA, 'county_'),
    },
  ],
};

export const listBoxIcons = {
  moveLeft: <span className="ca-gov-icon-caret-left" />,
  moveAllLeft: <span key={0} className="ca-gov-icon-caret-two-left" />,
  moveRight: <span className="ca-gov-icon-caret-right" />,
  moveAllRight: <span key={0} className="ca-gov-icon-caret-two-right" />,
};

export const officeOptions = [
  { value: 'assembly', label: 'Assembly' },
  { value: 'senate', label: 'Senate' },
  { value: 'city', label: 'City' },
  { value: 'county', label: 'County' },
];

export class ProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      availableDistrictOptions: districtOptions['assembly'][0].options,
      isNotified: true,
      pure: true
    };
  }

  componentDidMount() {
    const { initialValues, getOfficialList, officeValue } = this.props;

    if (!initialValues) {
      getOfficialList();
    }

    this.updateDistrictOptions(officeValue);
    //this.props.reset();
  }

  componentDidUpdate(prevProps) {
    const { officeValue, initialValues } = this.props;

    if (officeValue !== prevProps.officeValue) {
      this.updateDistrictOptions(officeValue);
    }

    if (initialValues && this.state.pure) {
      this.setState({ ...this.state, isNotified: initialValues.isNotified, pure: false })
    }
  }

  getAllDistrictOptions = () => {
    var tmp = [];
    for (var propName in districtOptions) {
      tmp = tmp.concat(districtOptions[propName]);
    }

    return tmp;
  };

  updateDistrictOptions = (officeValue) => {
    // have a office selected, update list
    if (officeValue) {
      var tmp = [];
      for (var opt in districtOptions[officeValue][0].options) {
        tmp.push(districtOptions[officeValue][0].options[opt].value);
      }

      this.setState({ ...this.state, availableDistrictOptions: tmp });
    }
  };

  toggleNotifications = async () => {
    console.log(this.state);
    await this.setState({ ...this.state, isNotified: !this.state.isNotified });
    console.log(!this.state.isNotified);
    console.log("I'm toggling!!");
    console.log(this.state)
  };

  onSubmit = (values) => {
    const { updateOfficial } = this.props;
    const { selected } = this.state;

    var districts = [];
    var cities = [];
    var counties = [];

    selected.forEach(function (value) {
      var pre = value.substring(0, value.indexOf('_'));
      var val = value.substring(value.indexOf('_') + 1);

      switch (pre) {
        case 'city':
          cities.push(val);
          break;
        case 'county':
          counties.push(val);
          break;
        case 'a':
        case 's':
        default:
          districts.push(value);
          break;
      }
    });

    values.district = districts;
    values.county = counties;
    values.city = cities;
    values.isNotified = this.state.isNotified;

    console.log("from submit:", this.state);


    updateOfficial(values);

  };

  render() {
    const { handleSubmit, pristine, initialValues, change } = this.props;
    const { availableDistrictOptions } = this.state;

    if (pristine && null !== initialValues) {
      var tmp = initialValues.district;

      if (null !== initialValues.city) {
        initialValues.city.forEach(function (city) {
          if ('' !== city) {
            tmp.push('city_' + city);
          }
        });
      }
      if (null !== initialValues.county) {
        initialValues.county.forEach(function (county) {
          if ('' !== county) {
            tmp.push('county_' + county);
          }
        });
      }
      //this.setState({ ...this.state, selected: [...new Set(tmp)] }); ?? doesn't work
      this.state.selected = [...new Set(tmp)];     
    }

    return (
      <ConnectedAsync>
        <form onSubmit={handleSubmit(this.onSubmit)} className={'m-t-md'}>
          <div className={'group'}>
            <FieldInput
              name={'fName'}
              label={'First Name'}
              className={'third'}
              required
            />
            <FieldInput
              name={'mName'}
              label={'Middle Name'}
              className={'third'}
            />
            <FieldInput
              name={'lName'}
              label={'Last Name'}
              className={'third'}
              required
            />
          </div>
          <div className={'group'}>
            <FieldInput
              name={'email'}
              label={'Email Address'}
              className={'full-width'}
              disabled
            />
          </div>
          <div className={'group'} id={'notifications'}>
            <FieldRadioButtons
              id={'office'}
              name={'office'}
              label={'Select an area to request notifications.'}
              className={'third'}
              options={officeOptions}
              //required={this.state.isNotified}
              disabled={!this.state.isNotified}
            />
            <div className={'two-thirds'}>
              <DualListBox
                id={'district'}
                name={'district'}
                options={this.getAllDistrictOptions()}
                selected={this.state.selected}
                available={availableDistrictOptions}
                onChange={(selected) => {
                  this.setState({ selected });
                  change('pristine', false);
                }}
                //required={this.state.isNotified}
                disabled={!this.state.isNotified}
                icons={listBoxIcons}
              />
            </div>
          </div>

          <div className={'p-y-sm'}>
            <label>
              {'Receive Notifications '}
              <input
                type="checkbox"
                onChange={this.toggleNotifications}
                name={'isNotified'}
                checked={this.state.isNotified}
              />
            </label>
            <Button
              type={'submit'}
              text={'Submit'}
              variant={'primary'}
              className={'pull-right'}
            //disabled={pristine}
            />
          </div>
        </form>
      </ConnectedAsync>
    );
  }
}

ProfileForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  initialValues: T.object,
  officeValue: T.string,
  getOfficialList: T.func.isRequired,
  updateOfficial: T.func.isRequired,
};

ProfileForm.defaultProps = {
  initialValues: null,
  officeValue: null,
};

export const mapStateToProps = createStructuredSelector({
  initialValues: selectors.getOfficial(), // populate inital form values,
  officeValue: (state) => formValueSelector('ProfileForm')(state, 'office'),
});

export const mapDispatchToProps = (dispatch) => ({
  getOfficialList: () => dispatch(actions.getOfficialListRequest()),
  updateOfficial: (values) => dispatch(actions.updateOfficialRequest(values)),
});

const withReduxForm = reduxForm({
  form: 'ProfileForm',
  destroyOnUnmount: false,
})(ProfileForm);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

export default withRedux;
