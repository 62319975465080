import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { ConnectedAsync, Button, FieldInput } from 'state-template';

import * as actions from '../actions';
import * as selectors from '../selectors';

// TODO: move to ST
const validatePassMatch = (value, state) => (
  state.pass === value
    ? undefined
    : 'Passwords do not match'
);

const validatePassStrength = (value, state) => {
  var validationMessage = '';
  if (state.pass?.length < 15) {
    return 'Password is too short, please use a minimum of 15 characters.';
  }
  else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(state.pass)) {
    return 'Please use a strong password containing at least one uppercase letter, one lowercase letter, one number, and one special character.';
  }
  else if (state.pass?.length > 128){
    return 'Password is too long.';
  }   
}

export class ResetPasswordForm extends React.PureComponent {
  onSubmit = (values) => {
    const { resetPassword, token } = this.props;
    resetPassword({ ...values, token });
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    return (
      <ConnectedAsync>
        <form onSubmit={handleSubmit(this.onSubmit)} className={'m-t-md'}>
          <div className={'group'}>
            <FieldInput name={'fName'} label={'First Name'} className={'third'} disabled />
            <FieldInput name={'mName'} label={'Middle Name'} className={'third'} disabled />
            <FieldInput name={'lName'} label={'Last Name'} className={'third'} disabled />
          </div>
          <div className={'group'}>
            <FieldInput name={'email'} label={'Email Address'} className={'full-width'} disabled />
          </div>
          <div className={'group'}>
            <FieldInput name={'pass'} label={'Password'} type={'password'} className={'half'} validate={[validatePassStrength]} required />
            <FieldInput name={'passConfirm'} label={'Confirm Password'} type={'password'} className={'half'} validate={[validatePassMatch]} required />
          </div>

          <div className={'p-y-sm'}>
            <Button
              type={'submit'}
              text={'Submit'}
              variant={'primary'}
              className={'pull-right'}
              disabled={pristine}
            />
          </div>
        </form>
      </ConnectedAsync>
    );
  }
}

ResetPasswordForm.propTypes = {
  token: T.string.isRequired,
  handleSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  resetPassword: T.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  initialValues: selectors.getToken(), // populate forms inital values
});

export const mapDispatchToProps = dispatch => ({
  resetPassword: props => dispatch(actions.resetPasswordRequest(props)),
});

const withReduxForm = reduxForm({ form: 'ResetPasswordForm', destroyOnUnmount: false })(ResetPasswordForm);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

export default withRedux;
