import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';


import {
  Button,
  ConnectedAsync,
  FieldDate,
  FieldFile,
  FieldInput,
  FieldSelect,
  FieldCheckboxes,
  FieldRadioButtons,
  Panel,
  fieldOptions,
  normalize,
  validate,
} from 'state-template';

import * as actions from '../actions';
import * as selectors from '../selectors';
import messages from '../messages';

const { API_URL } = window.config;

export class AssistanceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isActive: true, isHuman: true, isErrorHidden: true };
  }

  validatePDF = (value) => (!value
    || (value && value.type === 'application/pdf' && /\.pdf$/g.test(value.name))
    ? undefined
    : 'Required. Please submit a valid PDF document.');

    getCurrenDate = () => {
      const d = new Date();
      const currentDate =  [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
      ].join('-');
      return currentDate;
    }  

  validateCFDA = (value) => (!value || !/([0-9]{2})\.([0-9]{3})$/.test(value)
    ? 'Must be in the format xx.xxx where x is a number'
    : undefined);

 validateEndDate = (value, allValues) => {
  const startDate = allValues.projStart;
  const endDate = value;  
  if (endDate < this.getCurrenDate() || endDate < startDate) {
    return 'End Date must be in the future and greater than or equal to the Start Date';
  }
  return undefined;
};

  handleRadioChange = (value) => {
    if (value === 'yes') {
      this.setState({ isActive: false });
    } else {
      this.setState({ isActive: true });
    }
  };

  handleRecaptcha = (e) => {
    const data = {
      secret: '6Lc6KcgbAAAAAIZbVKvCmlv-lXzBFjtpLPzVDQ0H',
      response: e,
    };

    fetch(`${API_URL}/api/Login/verifyRecaptcha`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((d) => {
        if (d.score > 0.1) {
          this.setState((prevState) => ({ ...prevState, isHuman: true }));
        } else {
          this.setState((prevState) => ({ ...prevState, isHuman: false }));
        }
      });
  }

  onSubmit = (values) => {
    const { submitForm, history } = this.props;
    const { isHuman } = this.state;
    if (isHuman) {
      submitForm({ values, history });
    } else {
      this.setState((prevState) => ({ ...prevState, isErrorHidden: false }));
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const { isActive, isErrorHidden } = this.state;

    return (
      <ConnectedAsync>
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className={'m-t-md'}
          noValidate
        >
          <FieldInput required name={'orgName'} label={'Organization Name'} />
          <FieldInput
            required
            name={'email'}
            label={'Email Address'}
            validate={[validate.isValidEmail]}
          />
          <FieldInput
            required
            name={'cfda'}
            label={'Assistance Listing (CFDA)'}
            validate={[this.validateCFDA]}
          />
          <FieldInput
            required
            name={'fedAgency'}
            label={'Name of Federal Agency'}
          />
          <Panel title="Primary Place of Performance" variant={'understated'}>
            {messages.placeOfPerformanceText}
            <FieldInput
              required={isActive}
              disabled={!isActive}
              name={'street1'}
              label={'Street Line 1'}
            />
            <FieldInput
              disabled={!isActive}
              name={'street2'}
              label={'Street Line 2'}
            />
            <FieldInput
              required={isActive}
              disabled={!isActive}
              name={'city'}
              label={'City/Town'}
            />
            <FieldSelect
              required={isActive}
              disabled={!isActive}
              options={fieldOptions.countyOptionsCA}
              name={'county'}
              label={'County'}
            />
            <FieldInput disabled name={'state'} label={'State'} />
            <FieldInput
              required={isActive}
              disabled={!isActive}
              name={'zip'}
              label={'Zip Code'}
              normalize={normalize.normalizeZip}
            />
            <FieldInput disabled name={'country'} label={'Country'} />
          </Panel>

          <div className="group">
            <FieldDate
              required
              name={'projStart'}
              label={'Proposed Project Start Date'}
              className="half"
              aria-label={'Choose Date'}
            />
            <FieldDate
              required
              name={'projEnd'}
              label={'Proposed Project End Date'}
              className="half"
              validate={[this.validateEndDate]}
            />
          </div>
          <FieldFile
            required
            accept={'.pdf'}
            name={'applicantForm'}
            label={'Form 424 Attachment'}
            validate={[this.validatePDF]}
          />
          <div className={'submit-container'}>
            <Button type={'submit'} text={'Submit'} variant={'primary'} />
            <span className={'bot-msg'} hidden={isErrorHidden}>Unable to process request. Please try again in 2 minutes.</span>
          </div>
          <GoogleReCaptcha onVerify={this.handleRecaptcha} />

        </form>
      </ConnectedAsync>
    );
  }
}

AssistanceForm.propTypes = {
  handleSubmit: T.func.isRequired,
  assistanceList: T.array.isRequired,
  getAssistanceList: T.func.isRequired,
  submitForm: T.func.isRequired,
  history: T.shape({}).isRequired,
};

export const mapStateToProps = createStructuredSelector({
  assistanceList: selectors.getAssistanceList(),
});

export const mapDispatchToProps = (dispatch) => ({
  getAssistanceList: () => dispatch(actions.getAssistanceListRequest()),
  submitForm: (props) => dispatch(actions.submitFormRequest(props)),
});

const withReduxForm = reduxForm({
  form: 'AssistanceForm',
  initialValues: {
    state: 'California',
    country: 'United States of America',
  },
  destroyOnUnmount: false,
})(AssistanceForm);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm);
const withRouting = withRouter(withRedux);

export default withRouting;
