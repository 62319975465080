import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { LinkButton, Page, ConnectedAsync, Table } from 'state-template';
import PanelButton from './components/Panel/PanelButton';
import FiltersPanel from './components/FiltersPanel';
import SearchBox from './components/SearchBox';
import submissionBanner from '../../images/submissionBanner.png';
import messages from '../Home/messages';
import * as data from './data';
import * as actions from './actions';
import * as selectors from './selectors';

export class Submissions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filtersOpen: false,
    };
  }

  componentDidMount() {
    const { submissionList, getSubmissionList } = this.props;
    if (!submissionList.length) {
      getSubmissionList();
    }
  }

  toggleSection = (section) => {
    this.setState((state) => ({
      ...state,
      filtersOpen: false,
      [section]: !state[section],
    }));
  }

  toggleFilters = () => {
    this.toggleSection('filtersOpen');
  }

  onFilter = (values) => {
    const { updateFilters } = this.props;
    updateFilters(values);
  }

  onClearFilters = () => {
    const { resetForm } = this.props;
    resetForm('filters');
    this.onFilter({});
  }


  render() {
    const { submissionList, filters } = this.props;
    const { filtersOpen } = this.state;
    const { API_URL } = window.config;
    const url= `${API_URL}/api/Applications/DownloadAll`;
    return (
      <Page bannerSrc={submissionBanner} title={'Federal Assistance SF-424 Submissions'}>
        {messages.intro}        
        <ConnectedAsync>
          <h2>Submissions</h2>
          <div className={'SubmissionsPage__search'}>
            <SearchBox
              onSubmit={this.onSearch}
            />
            <PanelButton
              data-test={'toggle-filters'}
              text={`Filters (${Object.keys(filters).length})`}
              onClick={this.toggleFilters}
              isOpen={filtersOpen}
              aria-expanded={filtersOpen}
            />
            {filtersOpen && (
              <FiltersPanel
                data-test={'filters-panel'}
                onSubmit={this.onFilter}
                onClear={this.onClearFilters}
                filters={filters}
              />
            )}
          </div>
          
          <Table
            data={submissionList}
            columns={data.columns}
            hideSearch
            defaultSorted={data.defaultSorted}
          />
          <br/>
          <div className={'DownloadAll'}>
            <LinkButton text={'Download All Submissions'} href={url} variant={'primary'} />
          </div>
        </ConnectedAsync>
      </Page>
    );
  }
}

Submissions.propTypes = {
  submissionList: T.array.isRequired,
  getSubmissionList: T.func.isRequired,
  filters: T.object.isRequired,
  resetForm: T.func.isRequired,
  updateFilters: T.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  submissionList: selectors.getSubmissionList(),
  filters: selectors.getFilters(),
});

export const mapDispatchToProps = (dispatch) => ({
  getSubmissionList: () => dispatch(actions.getSubmissionListRequest()),
  updateFilters: (filters) => dispatch(actions.updateFilters(filters)),
  resetForm: (form) => dispatch(reset(form)),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps)(Submissions);
export default withRedux;
