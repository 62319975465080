import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ConnectedAsync, Table, Button } from 'state-template';

import * as data from './data';
import * as actions from '../actions';
import * as selectors from '../selectors';

export class Officials extends React.PureComponent {
  componentDidMount() {
    const { officialList, getOfficialList } = this.props;
    if (!officialList.length) {
      getOfficialList();
    }
  }

  buildColumns = () => {
    const { deleteOfficial } = this.props;
    // attach delete button to each row
    return data.columns.map((x) => {
      if (x.dataField === 'deleteBtn') {
        return {
          ...x,
          formatter: (cell, row) => (
            <Button
              text={'Delete'}
              variant={'default'}
              onClick={() => deleteOfficial(row.id)}
            />
          ),
        };
      }
      return x;
    });
  }

  render() {
    const { officialList } = this.props;

    return (
      <ConnectedAsync>
        <Table
          title={'Registrants'}
          data={officialList}
          columns={this.buildColumns()}
        />
      </ConnectedAsync>
    );
  }
}

Officials.propTypes = {
  officialList: T.array.isRequired,
  getOfficialList: T.func.isRequired,
  deleteOfficial: T.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  officialList: selectors.getOfficialList(),
});

export const mapDispatchToProps = dispatch => ({
  getOfficialList: () => dispatch(actions.getOfficialListRequest()),
  deleteOfficial: id => dispatch(actions.deleteOfficialRequest(id)),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps)(Officials);

export default withRedux;
