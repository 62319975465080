import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';
import { api, withAsync } from 'state-template';

import * as actions from './actions';
import * as C from './constants';

export function* getSubmissionList() {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Applications`;
  const data = yield call(api.request, url);
  yield put(actions.getSubmissionListSuccess(data));
}

export default function* submissionsSaga() {
  yield all([
    takeEvery(C.GET_SUBMISSION_LIST_REQUEST, withAsync(getSubmissionList)),
  ]);
}
