import React from 'react';
import { Container, LinkButton } from 'state-template';

const Message = ({ header, text }) => (
  <Container className={'text-center'}>
    <h2>{header}</h2>
    {text && <p>{text}</p>}
    <LinkButton to={'/auth'} text={'Login'} variant={'primary'} />
  </Container>
);

export default Message;
