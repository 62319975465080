import { date as dateUtils } from 'state-template';

// returns a date in local format, null if invalid
export const formattedDate = (date) => {
  if (dateUtils.isValid(date)) {
    return new Date(date).toLocaleDateString();
  }

  return null;
};
