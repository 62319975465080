import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ConnectedAsync, Table, A } from 'state-template';

import * as data from './data';
import * as actions from '../actions';
import * as selectors from '../selectors';
  const {API_URL}= window.config;

export class Applications extends React.PureComponent {
  componentDidMount() {
    const { applicationList, getApplicationList } = this.props;
    if (!applicationList.length) {
      getApplicationList();
    }
  }

  renderColumns = () => ({
    // link to save file for given submission
    fileName: (value, row) => (
      <A
        href={`${API_URL}/api/Applications/Download/${row.id}`}
        text={value}
      />
    ),
  })

  render() {
    const { applicationList } = this.props;

    return (
      <ConnectedAsync>
        <Table
          title={'Applications'}
          data={applicationList}
          columns={data.columns}
        />
      </ConnectedAsync>
    );
  }
}

Applications.propTypes = {
  applicationList: T.array.isRequired,
  getApplicationList: T.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  applicationList: selectors.getApplicationList(),
});

export const mapDispatchToProps = dispatch => ({
  getApplicationList: () => dispatch(actions.getApplicationListRequest()),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps)(Applications);

export default withRedux;
