// import { delay } from 'redux-saga';
import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';
import { api, withAsync } from 'state-template';
import { submitAsyncForm } from '../../utils/formUtils';
import * as actions from './actions';
import * as C from './constants';

export function* verfiyToken(action) {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Official/verifyTokenJson`;
  const options = { method: 'POST', body: JSON.stringify(action.payload) };
  const data = yield call(api.request, url, options);
  yield put(actions.verifyTokenSuccess(data));
}

export function* registerOfficial(action) {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Official/RegisterJson`;
  const options = { method: 'POST', body: JSON.stringify(action.payload) };
  let data;
  try {
      data = yield call(api.request, url, options);
      
      yield put(actions.registerOfficialSuccess(data));
  }
  catch(error) {
    yield put(actions.registerOfficialFailure(error));
  }  
}

export function* resetPassword(action) {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Official/ResetPasswordJson`;
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
  };

  const config = {
    formName: 'ResetPasswordForm',
    callApi: call(api.request, url, options),
    onSuccess: actions.resetPasswordSuccess,
    successMessage: 'Successfully Updated Password!',
    onResetError: actions.resetPasswordDuplicateFailure,
  };
  
  yield submitAsyncForm(config);
}

export function* confirm(action) {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Login/ConfirmAccount`;
  const options = { method: 'POST', body: JSON.stringify(action.payload) };
  const data = yield call(api.request, url, options);
  yield put(actions.confirmSuccess(data));
}

export default function* dashboardSaga() {
  yield all([
    takeEvery(C.VERIFY_TOKEN_REQUEST, withAsync(verfiyToken)),
    takeEvery(C.REGISTER_OFFICIAL_REQUEST, withAsync(registerOfficial)),
    takeEvery(C.RESET_PASSWORD_REQUEST, resetPassword),
    takeEvery(C.CONFIRM_REQUEST, confirm),
  ]);
}
