import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { configureStore, ConnectedMessages } from 'state-template';

import 'state-template/dist/style/core/css/cagov.core.min.css';
import 'state-template/dist/style/core/css/colorscheme-oceanside.css';
import './style/style.scss';
import './style/overrides.scss';

import App from './containers/App';
import authReducer from './containers/Auth/reducer';
import authSaga from './containers/Auth/saga';
import dashboardReducer from './containers/Dashboard/reducer';
import dashboardSaga from './containers/Dashboard/saga';
import homeReducer from './containers/Home/reducer';
import homeSaga from './containers/Home/saga';
import registrationReducer from './containers/Registration/reducer';
import registrationSaga from './containers/Registration/saga';
import submissionsReducer from './containers/Submissions/reducer';
import submissionsSaga from './containers/Submissions/saga';
import registrantsReducer from './containers/Registrants/reducer';
import registrantsSaga from './containers/Registrants/saga';

const reducers = {
  auth: authReducer,
  dashboard: dashboardReducer,
  home: homeReducer,
  registration: registrationReducer,
  submissions: submissionsReducer,
  registrants: registrantsReducer,
};

const sagas = [
  authSaga,
  dashboardSaga,
  homeSaga,
  registrationSaga,
  submissionsSaga,
  registrantsSaga,
];

const store = configureStore({ reducers, sagas });


ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={'6Lc6KcgbAAAAALR13LHWzbWuSFEG6YMzYm4BAus6'}
    >
      <App />
    </GoogleReCaptchaProvider>
    <ConnectedMessages />
  </Provider>,
  document.getElementById('root'),
);
