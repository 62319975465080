import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ConnectedAsync, Table } from 'state-template';

import * as data from './data';
import * as actions from '../actions';
import * as selectors from '../selectors';

export class Applicants extends React.PureComponent {
  componentDidMount() {
    const { applicantList, getApplicantList } = this.props;
    if (!applicantList.length) {
      getApplicantList();
    }
  }

  render() {
    const { applicantList } = this.props;

    return (
      <ConnectedAsync>
        <Table
          title={'Applicants'}
          data={applicantList}
          columns={data.columns}
        />
      </ConnectedAsync>
    );
  }
}

Applicants.propTypes = {
  applicantList: T.array.isRequired,
  getApplicantList: T.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  applicantList: selectors.getApplicantList(),
});

export const mapDispatchToProps = dispatch => ({
  getApplicantList: () => dispatch(actions.getApplicantListRequest()),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps)(Applicants);

export default withRedux;
