import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  Button,
  ConnectedAsync,
  FieldInput,
  validate,
} from 'state-template';

import * as actions from '../actions';

export class RequestResetForm extends React.PureComponent {
  componentWillUnmount() {
    const { onClickCancel } = this.props;
    // set as not resetting password when leaving this component
    onClickCancel();
  }

  onSubmit = (values) => {
    const { requestReset } = this.props;
    requestReset(values);
  }

  render() {
    const { handleSubmit, error, onClickCancel } = this.props;

    return (
      <ConnectedAsync>
        <form onSubmit={handleSubmit(this.onSubmit)} className={'m-t-sm'}>
          {error && <div className={'alert alert-danger'}>{error}</div>}

          <FieldInput
            required
            name={'email'}
            label={'Email Address'}
            validate={[validate.isValidEmail]}
          />

          <div className={'p-y-sm'}>
            <Button text={'Cancel'} variant={'default'} onClick={onClickCancel} />
            <Button type={'submit'} text={'Submit'} variant={'primary'} className={'pull-right'} />
          </div>
        </form>
      </ConnectedAsync>
    );
  }
}

RequestResetForm.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  onClickCancel: T.func.isRequired,
  requestReset: T.func.isRequired,
};

RequestResetForm.defaultProps = {
  error: null,
};

export const mapDispatchToProps = dispatch => ({
  requestReset: props => dispatch(actions.requestResetRequest(props)),
  onClickCancel: () => dispatch(actions.setIsResetting(false)),
});

const withReduxForm = reduxForm({ form: 'RequestResetForm', destroyOnUnmount: false })(RequestResetForm);
const withRedux = connect(null, mapDispatchToProps)(withReduxForm);

export default withRedux;
