// import { delay } from 'redux-saga';
import {
  all, takeEvery, takeLatest, call, put, take, race, delay, select
} from 'redux-saga/effects';
import { auth, api } from 'state-template';
import { submitAsyncForm } from '../../utils/formUtils';
import * as actions from './actions';
import * as C from './constants';

const MAX_LOGIN_ATTEMPTS = 5;
const LOCKOUT_TIME = 120*60*1000; //120 minutes

export function* login(action) {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Login/LoginJson`;
  const lockoutExpires = localStorage.getItem('lockoutExpires');
  const isLockedOut = lockoutExpires &&
    Date.now() < parseInt(lockoutExpires, 10);
  if(isLockedOut){
    yield put(actions.stillLockedOut());
    return;
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
  };
  const tokenKey = 'opr_jwt';
  const config = {
    formName: 'LoginForm',
    callApi: auth.authenticate({ url, options, tokenKey }),
    onSuccess: actions.loginSuccess,
    errorMessage: 'Invalid Email or Password.',
    errorStatus: 401,
  };

  yield submitAsyncForm(config);
}

export function* logout() {
  auth.clearToken();
  yield put(actions.logoutDone());
}

// try logging in, cancel if get a logout action
export function* loginFlow(action) {
  yield race({
    task: call(login, action),
    cancel: take(C.LOGOUT),
  });
}

export function* handleLoginFailure(action){
  const loginAttempts = yield select((state) => 
  state.auth.loginAttempts);
  yield
  put(actions.incrementLoginAttempts());

  if(loginAttempts >= MAX_LOGIN_ATTEMPTS){
    const lockoutExpires = Date.now() + LOCKOUT_TIME;
    yield put(actions.enforceLockout());
    localStorage.setItem('lockoutExpires', lockoutExpires.toString());
  }
}

export function* requestReset(action) {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Official/RequestResetJson`;
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const config = {
    formName: 'RequestResetForm',
    callApi: call(api.request, url, options),
    onSuccess: actions.requestResetSuccess,
    errorMessage: 'Invalid Email.',
    successMessage: 'Successfully Submitted! An email has been sent.',
  };

  yield submitAsyncForm(config);
}

export default function* authSaga() {
  yield all([
    takeEvery(C.LOGIN_REQUEST, loginFlow),
    takeLatest(C.LOGOUT, logout),
    takeLatest(C.REQUEST_RESET_REQUEST, requestReset),
    takeLatest(C.LOGIN_FAILURE, handleLoginFailure),
  ]);
}
