import React from 'react';

export const columns = [
  {
    sort: true,
    dataField: 'email',
    text: 'Email',
  }, {
    sort: true,
    dataField: 'fName',
    text: 'First Name',
  }, {
    sort: true,
    dataField: 'mName',
    text: 'Middle Name',
  }, {
    sort: true,
    dataField: 'lName',
    text: 'Last Name',
  }, {
    sort: true,
    dataField: 'district',
    text: 'Assembly District',
    formatter: (e, row) => {
      if (!row.district) { return null; }
      var assembly = [];
      row.district.forEach(function (value){
        if(value.startsWith("a_")){
          assembly.push(value.substring(value.indexOf("_") + 1));
        }
      });
      return (<p>{assembly.join(", ")}</p>);
    },
  }, {
    sort: true,
    dataField: 'district',
    text: 'Senate District',
    formatter: (e, row) => {
      if (!row.district) { return null; }
      var assembly = [];
      row.district.forEach(function (value){
        if(value.startsWith("s_")){
          assembly.push(value.substring(value.indexOf("_") + 1));
        }
      });
      return (<p>{assembly.join(", ")}</p>);
    },
  }, {
    sort: true,
    dataField: 'county',
    text: 'County',
  }, {
    sort: true,
    dataField: 'reset',
    text: 'Activated',
    formatter: cell => (
      cell ? 'No' : 'Yes'
    ),
  }, {
    dataField: 'deleteBtn',
    text: '',
    isDummyField: true,
  },
];
