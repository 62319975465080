import { fieldOptions } from 'state-template';

const schema = {
  cfdaNumber: {
    name: 'cfda',
    label: 'CFDA Number',
  },
  projStart: {
    name: 'projStart',
    label: 'Project Start Date',
  },
  submission: {
    name: 'submission',
    label: 'Date Received',
  },
  projEnd: {
    name: 'projEnd',
    label: 'Project End Date',
  },
  city: {
    name: 'city',
    label: 'City/Town',
  },
  assembly: {
    name: 'assembly',
    label: 'Assembly District',
  },
  senate: {
    name: 'senate',
    label: 'Senate District',
  },
  county: {
    name: 'county',
    label: 'County',
    options: [
      { value: '', label: 'ALL' },
      ...fieldOptions.countyOptionsCA,
    ],
  }
};

export default schema;
