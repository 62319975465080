import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Page, NavTabs } from 'state-template';

import * as authSelectors from '../Auth/selectors';
import routes from './routes';

export class Dashboard extends React.PureComponent {
  getRoutes = () => {
    const { role } = this.props;
    return routes.filter((x) => {
      if (!x.roles) { return true; }
      return x.roles.includes(role);
    });
  }

  render() {
    const tabRoutes = this.getRoutes();

    return (
      <Page title={'Dashboard'}>
        <NavTabs routes={tabRoutes} />
      </Page>
    );
  }
}

Dashboard.propTypes = {
  role: T.string.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  role: authSelectors.getRole(),
});

const withRedux = connect(mapStateToProps)(Dashboard);

export default withRedux;
