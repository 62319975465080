import Applicants from './Applicants';
import Applications from './Applications';
import Officials from './Officials';
import ProfileForm from './ProfileForm';

import * as C from '../App/constants';

export const routesByKey = {
  applicants: {
    key: 'applicants',
    name: 'Applicants',
    path: '',
    exact: true,
    component: Applicants,
    roles: [C.ROLES.ADMIN],
  },
  applications: {
    key: 'applications',
    name: 'Applications',
    path: '',
    exact: true,
    component: Applications,
    roles: [C.ROLES.OFFICIAL],
  },
  officials: {
    key: 'officials',
    name: 'Registrants',
    path: '/officials',
    exact: true,
    component: Officials,
    roles: [C.ROLES.ADMIN],
  },
  profile: {
    key: 'profile',
    name: 'Profile',
    path: '/profile',
    exact: true,
    component: ProfileForm,
    roles: [C.ROLES.OFFICIAL],
  },
};

export default Object.values(routesByKey);
