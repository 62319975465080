/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as T from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  Button, FieldInput, FieldSelect,
} from 'state-template';

import schema from './schema';
import Panel from '../Panel';
import FieldDateRange from '../FieldDateRange';

export const FiltersPanel = ({
  onClear,
  pristine,
  handleSubmit,
  filters,
}) => {
  const { API_URL } = window.config;

  const handleFilteredExport = async () => {
    let url = `${API_URL}/api/Applications/DownloadFiltered?`;

    if (filters.assembly && filters.assembly.length > 0) {
      url += `assembly=${encodeURIComponent(filters.assembly)}&`;
    }
    if (filters.cfda && filters.cfda.length > 0) {
      url += `cfda=${encodeURIComponent(filters.cfda)}&`;
    }
    if (filters.city && filters.city.length > 0) {
      url += `city=${encodeURIComponent(filters.city)}&`;
    }
    if (filters.county && filters.county.length > 0) {
      url += `county=${encodeURIComponent(filters.county)}&`;
    }
    if (filters['projEnd--end'] && filters['projEnd--end'].length > 0) {
      url += `projEndEnd=${filters['projEnd--end']}&`;
    }
    if (filters['projEnd--start'] && filters['projEnd--start'].length > 0) {
      url += `projEnd=${filters['projEnd--start']}&`;
    }
    if (filters['projStart--end'] && filters['projStart--end'].length > 0) {
      url += `projStartEnd=${filters['projStart--end']}&`;
    }
    if (filters['projStart--start'] && filters['projStart--start'].length > 0) {
      url += `projStart=${filters['projStart--start']}&`;
    }
    if (filters.senate && filters.senate.length > 0) {
      url += `senate=${encodeURIComponent(filters.senate)}&`;
    }
    if (filters['submission--end'] && filters['submission--end'].length > 0) {
      url += `submissionEnd=${filters['submission--end']}&`;
    }
    if (filters['submission--start'] && filters['submission--start'].length > 0) {
      url += `submission=${filters['submission--start']}&`;
    }
    document.location.href = url;
  };

  return (
    <Panel title={'Filters'}>
      <form className={'row'} onSubmit={handleSubmit}>
        <FieldInput {...schema.cfdaNumber} className={'col-sm-4'} />
        <FieldSelect {...schema.county} className={'col-sm-4'} />
        <FieldInput {...schema.city} className={'col-sm-4'} />

        <FieldInput {...schema.assembly} className={'col-sm-4'} />
        <FieldInput {...schema.senate} className={'col-sm-4'} />

        <FieldDateRange {...schema.projStart} className={'col-sm-6'} />
        <FieldDateRange {...schema.projEnd} className={'col-sm-6'} />
        <FieldDateRange {...schema.submission} className={'col-sm-6'} />

        <div className={'FilterPanel__actions col-12'}>
          <Button text={'Clear'} variant={'default'} onClick={onClear} disabled={pristine} />
          <Button text={'Submit'} variant={'primary'} type={'submit'} />
          {/* <Button text={'Export Filtered'} variant={'secondary'} onClick={handleFilteredExport} /> */}

          {Object.keys(filters).length > 0 ? <Button text={'Export Filtered'} variant={'secondary'} onClick={handleFilteredExport} /> : ''}
        </div>
      </form>
    </Panel>
  )
};

FiltersPanel.propTypes = {
  /** Clears the form */
  onClear: T.func.isRequired,
  /** Provided by redux-form, determines if form has been changed */
  pristine: T.bool.isRequired,
  handleSubmit: T.func.isRequired,
  filters: T.object.isRequired,
};

const usingForm = reduxForm({
  form: 'filters',
  destroyOnUnmount: false,
})(FiltersPanel);

export default usingForm;
