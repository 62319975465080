import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Page } from 'state-template';

import Message from './Message';
import RegistrationForm from './RegistrationForm';
import ResetPasswordForm from './ResetPasswordForm';
import * as actions from './actions';
import * as selectors from './selectors';

export class Registration extends React.PureComponent {
  state = { token: null, isResetting: false, isRegistering: false, isErr: false, isConfirming: false  }

  async componentDidMount() {
    await this.getUrlParams(); // wait for state to update
    const { isConfirming } = this.state;
    this.verifyToken();
    if(isConfirming == true) {
    this.confirmRequest()
    }
  }

  getUrlParams = () => {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    
    const token = urlParams.get('token');
    const isResetting = urlParams.get('reset');
    const isConfirming = urlParams.get('confirm');
    const isRegistering = null === token ? true : false;
    const email = urlParams.get('email');


    this.setState({ token, isResetting, isRegistering, isConfirming, email });
  }

  verifyToken = () => {
    const { verifyToken } = this.props;
    const { token } = this.state;
    if( null !== token ){
      verifyToken({ token });
    }
  }

  confirmRequest = () => {
    const { confirmRequest } = this.props;
    const { token, email } = this.state;    
    
    if( null !== token ){
      confirmRequest({ token, email });
    }
  }

  renderForm = (email) => {
    const { isResetting, token } = this.state;
    const initialValues = { email };

    if (isResetting) {
      return <ResetPasswordForm token={token} />;
    }
    
    if( email ){
      return <RegistrationForm token={token} initialValues={initialValues} />;
    }else{
      return <RegistrationForm token={token} />;
    }
  }

  renderSuccessMessage = () => {
    const { isResetting } = this.state;
    const header = isResetting ? 'Successful Password Reset!' : 'Successful Registration! You must confirm your account to login, a confirmation email has been sent.';
    return <Message header={header} />;
  }

  renderFailureMessage = () => {
    const header = 'User already exists.';
    return <Message header={header} />;
  }


  renderConfirmMessage = () => {
    const header = 'User account confirmed! You can now login.';
    return <Message header={header} />;
  }

  //If password is in PasswordHistory table
  renderPasswordResetFailureMessage = () => {
    const header = 'Cannot reuse last 10 passwords.';
    return <Message header={header} />;
  }



  render() {
    const { isRegistered, token: { email }, isErr, isConfirmed } = this.props;
    const { isResetting, isRegistering, isConfirming } = this.state;
    const title = isResetting ? 'Reset Password' : 'Registration';
    let content;
    
    if( email || isRegistering && !isConfirming){
      content = this.renderForm(email);
    }
    
    //form completed, display success message
    if (isRegistered) {
      content = this.renderSuccessMessage();
    }
    
    if (isErr) {
      if(isRegistering){
        content = this.renderFailureMessage();
      }
      else if (isResetting){
        content = this.renderPasswordResetFailureMessage();
      } 
    }       
    
    if (isConfirmed) {
      content = this.renderConfirmMessage();      
    }       
    
    return (
      <Page title={title}>
        {content}
      </Page>
    );
  }
}

Registration.propTypes = {
  location: T.shape({
    search: T.string.isRequired,
  }).isRequired,
  token: T.object,
  isRegistered: T.bool.isRequired,
  verifyToken: T.func.isRequired,
  isErr: T.bool.isRequired,
  isConfirmed: T.bool.isRequired,
};

Registration.defaultProps = {
  token: {},
};

export const mapStateToProps = createStructuredSelector({
  token: selectors.getToken(),
  isRegistered: selectors.getIsRegistered(),
  isErr: selectors.getIsErr(),
  isConfirmed: selectors.getIsConfirmed(),
});

export const mapDispatchToProps = dispatch => ({
  verifyToken: values => dispatch(actions.verifyTokenRequest(values)),
  confirmRequest: values => dispatch(actions.confirmRequest(values)),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps)(Registration);

export default withRedux;
