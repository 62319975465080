import { createSelector } from 'reselect';

const selectDashboard = state => state.dashboard;

export const getApplicationList = () => createSelector(selectDashboard, x => x.applicationList);
export const getApplicantList = () => createSelector(selectDashboard, x => x.applicantList);
export const getOfficialList = () => createSelector(selectDashboard, x => x.officialList);
export const getOfficial = () => createSelector(selectDashboard, x => (
  x.officialList ? x.officialList[0] : null
));
