/* eslint-disable camelcase */
import React, {useEffect, useState, useLayoutEffect} from 'react';
import T from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, ConnectedAsync, FieldInput, validate } from 'state-template';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { createStructuredSelector } from 'reselect';
import * as actions from '../actions';
import * as selectors from '../selectors';

const { API_URL } = window.config;

export class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      isHuman: true,
    };
  }

  onSubmit = (values) => {
    const { login, isLockedOut } = this.props;
    const { isHuman } = this.state;
    if (isLockedOut){
      //display message that the account is locked
      console.log('The account is currently locked.');
      return;
    }
    if (isHuman) {
      login(values);
    }
  };

  handleRecaptcha = (e) => {
    const data = {
      secret: '6Lc6KcgbAAAAAIZbVKvCmlv-lXzBFjtpLPzVDQ0H',
      response: e,
    };

    fetch(`${API_URL}/api/Login/verifyRecaptcha`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((d) => {
        if (d.score > 0.1) {
          this.setState((prevState) => ({ ...prevState, isHuman: true }));
        } else {
          this.setState((prevState) => ({ ...prevState, isHuman: false }));
        }
      });
  }

  render() {
    const { isHuman } = this.state;
    const { handleSubmit, auth_error, onClickReset } = this.props;
    const lockoutExpires = localStorage.getItem('lockoutExpires');
    if(lockoutExpires && Date.now() < parseInt(lockoutExpires, 10)){
      return (
        <div className="alert alert-danger" role="alert">
          Account has been locked due to multiple failed login
          attempts. Please try again later.
        </div>
      );
    }
    return (
      <ConnectedAsync>
        <form onSubmit={handleSubmit(this.onSubmit)} className={'m-t-sm'}>
          {
            !isHuman
              ? (<div className={'alert alert-danger'}>Unable to process request. Please try again in 2 minutes.</div>)
              : null
          }

          {
            auth_error ? 
            auth_error == "You must confirm your email to login."
            ? auth_error && (<div className={'alert alert-danger'}>{auth_error}&nbsp;A confirmation email has been resent, please click the link to confirm your account.</div>) :
            (<div className={'alert alert-danger'}>{auth_error}</div>)
            : null
          }
          

          <FieldInput
            required
            name={'fedassisteml'}
            label={'Email Address'}
            type={'email'}
            validate={[validate.isValidEmail]}
          />

          <FieldInput
            required
            name={'fedassistpwd'}
            label={'Password'}
            type={'password'}
          />

          <div className={'p-y-sm'}>
          <Button
              text={'Reset Password'}
              variant={'default'}
              onClick={onClickReset}
            />
            <Button
              type={'submit'}
              text={'Submit'}
              variant={'primary'}
              className={'pull-right'}
            />
          </div>
          <GoogleReCaptcha onVerify={this.handleRecaptcha} />
        </form>
      </ConnectedAsync>
    );
  }
}

LoginForm.defaultProps = {
  auth_error: '',
};

LoginForm.propTypes = {
  auth_error: T.string,
  handleSubmit: T.func.isRequired,
  login: T.func.isRequired,
  onClickReset: T.func.isRequired,
  isLockedOut: T.bool.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  login: (props) => dispatch(actions.loginRequest(props)),
  onClickReset: () => dispatch(actions.setIsResetting(true)),
});
export const mapStateToProps = createStructuredSelector({
  auth_error: selectors.getError(),
  isLockedOut: selectors.getIsLockedOut(),
});

const withReduxForm = reduxForm({ form: 'LoginForm', destroyOnUnmount: false })(
  LoginForm,
);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

export default withRedux;
