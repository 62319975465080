import React from 'react';
import { put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { asyncActions, messagesActions } from 'state-template';
import * as actions from '../containers/Auth/actions';
import * as registrationActions from '../containers/Registration/actions';
import { call } from 'redux-saga/effects';
import decode from 'jwt-decode';

export const mapFields = ({ fields }) =>
  Object.keys(fields).map((name) => {
    const { component: C, ...fieldProps } = fields[name];

    return <C key={name} name={name} {...fieldProps} />;
  });

export function* submitAsyncForm(config) {
  const {
    formName,
    callApi,
    onSuccess,
    errorMessage,
    onResetError,
    successMessage,
    errorStatus = 400,
  } = config;

  let submissionError;
  let response;

  try {
    yield put(asyncActions.increaseLoading());
    yield put(startSubmit(formName));

    // register success message into global messaging system
    const response = yield callApi;

    if (successMessage) {
      yield put(
        messagesActions.registerMessage({
          id: `${formName}Success`,
          children: successMessage,
          variant: 'primary',
        })
      );
    }
    yield put(onSuccess(response));
  } catch (error) {
    if(error.errorMessage){
    yield put(actions.loginFailed({ auth_error: error.errorMessage }));
    yield put(registrationActions.resetPasswordDuplicateFailure({ auth_error: error.errorMessage }));
    }
    else {
      yield put(actions.loginFailed({ auth_error: "Invalid email or password." }));
    }    
  } finally {
    yield put(asyncActions.decreaseLoading());
    yield put(stopSubmit(formName, submissionError));
  }
}
