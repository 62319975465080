import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';
import { api, withAsync } from 'state-template';

import * as actions from './actions';
import * as C from './constants';

export function* getRegistrantList() {
  const {API_URL}= window.config;
  const url = `${API_URL}/api/Official`;
  const data = yield call(api.request, url);
  yield put(actions.getRegistrantListSuccess(data));
}

export default function* registrantsSaga() {
  yield all([
    takeEvery(C.GET_REGISTRANT_LIST_REQUEST, withAsync(getRegistrantList)),
  ]);
}
